<template>
  <div class="modal-body">
    <div class="my-2">
      <material-input
        id="nom"
        type="text"
        label="Nom"
        name="nom"
        :modelValue="nom"
        @update:modelValue="(newValue) => (nom = newValue)"
      />
    </div>
  </div>
  <div v-if="!loading" class="modal-footer">
    <button type="button" class="btn btn-secondary" @click="modalToggle">
      Annuler
    </button>
    <button type="button" class="btn btn-primary" @click="commercialCreate">
      Créer Commercial
    </button>
  </div>
  <div v-else class="modal-footer text-center">
    Création du commercial en cours
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import { mapActions } from "vuex";

export default {
  components: {
    MaterialInput,
  },
  data() {
    return {
      nom: "",
      loading: false,
    };
  },
  name: "CommercialCreate",
  emits: ['modaltoggle'],
  methods: {
    ...mapActions(["createCommercial"]),
    modalToggle() {
      this.$emit('modaltoggle')
    },
    commercialCreate() {
      this.loading = true;
      
      const data = {
        nom: this.nom
      }

      if (this.nom == "" || this.nom == null) {
        this.$swal("Champs invalide", "Veuillez renseigner un nom.", "error");
        this.loading = false;
        return;
      }

      this.createCommercial( { data: data } )
        .then((response) => {
          console.log(response);
          this.modalToggle();

          this.$swal.fire({
            position: "center-center",
            icon: "success",
            title: "Commercial créé avec succès",
            showConfirmButton: false,
            timer: 1000,
          });
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
  },
};
</script>