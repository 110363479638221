<template>
  <div class="card mb-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="my-2">Opérations réalisées</h6>
        </div>
        <div class="col-6 text-end">
          <material-button 
            v-if="authorized"
            color="success"
            @click="addOpt"
          >Ajouter
          </material-button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0 p-3">
      <span v-if="opts === null" class="text">Chargement des opérations en cours...</span>
      <span v-else-if="opts.length === 0" class="text">Aucune opération existante.</span>
      <ul v-else class="list-group">
        <li v-for="r in opts" :key="r.id"
          class="list-group-item border-0 d-flex p-3 my-2 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm">
              <span class="me-sm-2">
                {{ new Date(r.attributes.createdAt).toLocaleString('fr-FR') }}
              </span>
              -
              <span class="text-dark ms-sm-2"
                >{{ r.attributes.operateur.data.attributes.username }}</span
              >
            </h6>
            <span class="mb-2 text-sm">
              Opération:
              <span class="text-dark ms-sm-2 font-weight-bold"
                >{{ r.attributes.operation }}</span
              >
            </span>
            <span v-if="r.attributes.remarque" class="mb-2 text-sm">
              Remarque:
              <span class="text-dark ms-sm-2 font-weight-bold"
                >{{ r.attributes.remarque }}</span
              >
            </span>
          </div>
          <div class="ms-auto text-end">
            <!-- <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;">
              <i class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i
              >Edit
            </a> -->
            <!-- <a
              class="btn btn-link text-danger text-gradient px-3 mb-0"
              href="javascript:;"
            >
              <i class="far fa-trash-alt me-2" aria-hidden="true"></i>Delete
            </a> -->
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    MaterialButton,
  },
  name: "OperationsCard",
  props: {
    id: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      opts: null,
      message: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters(["role"]),
    authorized() {
      return this.role === 'administrateur' || this.role === 'operateur'
    },
    operations() {
      switch (this.type) {
        case 'z':
          return {
            select: true,
            opts: [
              'Affutage',
              'Double Affutage',
              'Changement de Pastilles Carbures',
              'Rectification de dents',
              'Vérification de Planage et Tensionnage',
              'Détalonnage'
            ],
            mach: [
              'Robot CHD270 + ND230',
              'Robot CHD251R2 + ND220',
              'CHD270',
              'CHD251R2',
              'CHD250',
              'CHC1300',
              'CHP20H',
              'AS810',
              'LG21H + CHHF',
              'Autre'
            ]
          }
        default:
          return {
            select: false,
          }
      }
    }
  },
  mounted() {
    this.getOpes()
  },
  methods: {
    ...mapActions(["getOperations", "createOperation"]),
    getOpes() {
      this.loading = true
      this.getOperations({ custom: `/?populate[0]=operateur&filters[outil][id][$eq]=${this.id}&sort[0]=createdAt:desc` })
        .then((response) => {
          this.message = ''
          this.opts = response.data.data
          this.loading = false
        })
        .catch((error) => {
          console.log(error);
          this.message = 'Une erreur est survenue lors de la récupération des opérations.'
          this.loading = false
        });
    },
    addOpt() {
      this.operations.select? this.addOptSelect() : this.addOptText()
    },
    async addOptSelect () {
      
      let optOpts = '<option value="">Opération réalisée</option>'
      let machOpts = '<option value="">Machine utilisée</option>'

      for (let o of this.operations.opts) {
        optOpts += `<option value="${o}">${o}</option>`
      }

      for (let m of this.operations.mach) {
        machOpts += `<option value="${m}">${m}</option>`
      }

      const { value: formValues } = await this.$swal.fire({
        title: 'Ajouter une opération',
        html:
          `<div class="w-100 align-center">
            <h6 class="mb-1">Opération: </h6>
            <select id="swal-operation" class="w-80">
              ${optOpts}
            </select>
            <h6 class="mb-1 mt-3">Machine: </h6>
            <select id="swal-machine" class="w-80">
              ${machOpts}
            </select>
            <h6 class="mt-3 mb-1">Remarque: </h6>
            <textarea id="swal-remarque" class="swal2-textarea mt-1" name="swal-remarque" style="width: 82%;" placeholder="Remarque particulière (optionnel)"></textarea>
          </div>`,
        focusConfirm: false,
        preConfirm: () => {
          return {
            operation: document.getElementById('swal-operation').value,
            machine: document.getElementById('swal-machine').value,
            remarque: document.getElementById('swal-remarque').value
          }
        }
      })

      if (!formValues) {
        return
      }

      if( formValues.operation == "" ) {
        this.$swal("Erreur", "Saisie invalide. Veuillez renseigner une opération.", "error");
        return
      }

      if( formValues.machine == "" ) {
        this.$swal("Erreur", "Saisie invalide. Veuillez renseigner une machine.", "error");
        return
      }

      const payload = {
        data: {
          operation: formValues.operation + ' / ' + formValues.machine,
          remarque: formValues.remarque,
          outil: this.id
        }
      }

      this.createOperation(payload)
      .then(() => {
        this.$swal
          .fire({
            position: "top-end",
            icon: "success",
            title: "Opération ajoutée avec succès",
            showConfirmButton: false,
            timer: 1000,
          })
          this.getOpes()
        })
      .catch((error) => {
        console.log(error);
        this.$swal("Erreur", "Oups, une erreur est survenue.", "error");
      });
    },
    async addOptText() {
      const { value: formValues } = await this.$swal.fire({
        title: 'Ajouter une opération',
        html:
          `<div class="w-100">
            <h6 class="mb-1">Opération: </h6>
            <textarea id="swal-operation" class="swal2-textarea mt-1" name="swal-operation" style="width: 80%;" placeholder="Opération (requis)"></textarea>
            <h6 class="mt-3 mb-1">Remarque: </h6>
            <textarea id="swal-remarque" class="swal2-textarea mt-1" name="swal-remarque" style="width: 80%;" placeholder="Remarque particulière (optionnel)"></textarea>
          </div>`,
        focusConfirm: false,
        preConfirm: () => {
          return {
            operation: document.getElementById('swal-operation').value,
            remarque: document.getElementById('swal-remarque').value
          }
        }
      })

      if (!formValues) {
        return
      }

      if( formValues.operation == "" ) {
        this.$swal("Erreur", "Saisie invalide. Veuillez renseigner une opération.", "error");
        return
      }

      const payload = {
        data: {
          operation: formValues.operation,
          remarque: formValues.remarque,
          outil: this.id
        }
      }
      this.createOperation(payload)
      .then(() => {
        this.$swal
          .fire({
            position: "top-end",
            icon: "success",
            title: "Opération ajoutée avec succès",
            showConfirmButton: false,
            timer: 1000,
          })
          this.getOpes()
        })
      .catch((error) => {
        console.log(error);
        this.$swal("Erreur", "Oups, une erreur est survenue.", "error");
      });
    }
  }
};
</script>
