<template>
  <div class="container py-4">
    <div class="row search pt-5">
      <div class="col-12">
        <div class="card p-5">
          <div class="card-header text-center">
            <h3>Scannez un QR Code pour rechercher un outil</h3>
          </div>
          <div class="card-body">
            <OutilSearch />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import OutilSearch from '../components/outils/OutilSearch.vue';

export default {
  name: "Search",
  components: {
    OutilSearch,
  }
}; 
</script>