<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-12 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted text-lg-end"
          >
            ©
            {{ new Date().getFullYear() }}, made with
            <i class="fa fa-heart"></i> by
            <a
              href="https://digit-factory.fr"
              class="font-weight-bold"
              target="_blank"
              >DIGIT FACTORY</a
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
