<template>
  <div class="modal-body">
    <div class="row">
      <div class="col-6 my-2">
        <material-input
          id="crm_code"
          type="text"
          label="Code CRM"
          name="crm_code"
          :modelValue="client.crm_code"
          @update:modelValue="(newValue) => (client.crm_code = newValue)"
        />
      </div>
      <div class="col-6 my-2">
        <material-input
          id="nom"
          type="text"
          label="Nom"
          name="nom"
          :modelValue="client.nom"
          @update:modelValue="(newValue) => (client.nom = newValue)"
        />
      </div>
      <div class="col-6 my-2">
        <select
          id="selectCommercial"
          v-model="client.commercial"
          :class="{ 'is-valid': client.commercial !== '' }"
          class="form-select w-100 px-3"
          data-style="btn btn-primary btn-round"
        >
          <option disabled value="">Sélectionner un commercial</option>
          <option v-for="c in commercials" :value="c.id" :key="c.id">
            {{ c.attributes.nom }}
          </option>
        </select>
      </div>
      <div class="col-6 my-2">
        <select
            id="select-jour"
            v-model="client.jour"
            :class="{ 'is-valid': client.jour != '' && client.jour != null }"
            class="form-select w-100 px-3"
            data-style="btn btn-primary btn-round"
          >
            <option disabled value="">Jour de tournée</option>
            <option value="lundi">Lundi</option>
            <option value="mardi">Mardi</option>
            <option value="mercredi">Mercredi</option>
            <option value="jeudi">Jeudi</option>
            <option value="vendredi">Vendredi</option>
            <option value="samedi">Samedi</option>
          </select>
      </div>
    </div>
  </div>
  <div v-if="!loading" class="modal-footer">
    <button type="button" class="btn btn-secondary" @click="modalToggle">
      Annuler
    </button>
    <button type="button" class="btn btn-primary" @click="customerCreate">
      Créer Client
    </button>
  </div>
  <div v-else class="modal-footer text-center">Création du client en cours</div>
</template>
  
  <script>
import MaterialInput from "@/components/MaterialInput.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    MaterialInput,
  },
  data() {
    return {
      client: {
        crm_code: '',
        nom: '',
        commercial: '',
        jour: ''
      },

      loading: false,
    };
  },
  mounted() {
    this.getCommercials()
    .catch(error => {
      console.log(error)
      this.$swal("Erreur", "Impossible de récupérer la liste des commerciaux", "error")
    })
  },
  name: "ClientCreate",
  emits: ["modaltoggle"],
  computed: {
    ...mapState(["commercials"]),
  },
  methods: {
    ...mapActions(["createCustomer", "getCustomers", "getCommercials"]),
    modalToggle() {
      this.$emit("modaltoggle");
    },
    customerCreate() {
      this.loading = true;

      if (this.client.crm_code == "" || this.client.crm_code == null) {
        this.$swal("Champs invalide", "Veuillez renseigner un code CRM.", "error");
        this.loading = false;
        return;
      }

      if (this.client.nom == "" || this.client.nom == null) {
        this.$swal("Champs invalide", "Veuillez renseigner un nom.", "error");
        this.loading = false;
        return;
      }

      if (this.client.jour == "" || this.client.jour == null) {
        this.$swal("Champs invalide", "Veuillez renseigner un jour de tournée.", "error");
        this.loading = false;
        return;
      }

      if (this.client.commercial == "" || this.client.commercial == null) {
        this.$swal.fire({
            title: "Attention",
            text: "Aucun commercial sélectionné. Continuer?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Oui, continuer',
            cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.isCanceled) {
                this.loading = false;
                return;
            }
        })
      }

      this.createCustomer({ data: this.client })
        .then((response) => {
          this.modalToggle();
          this.$swal.fire({
            position: "center-center",
            icon: "success",
            title: `Client ${response.data.data.attributes.nom} créé avec succès`,
            showConfirmButton: false,
            timer: 1000,
          });
          this.getCustomers({
            populate: '?populate[0]=commercial'
          }).catch(error => {
            console.log(error)
            this.$swal("Erreur", "Impossible de récupérer la liste des clients", "error")
          })
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>