<template>
  <div class="card bg-transparent shadow-xl">
    <div
      class="overflow-hidden position-relative border-radius-xl"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/curved14.jpg') + ')',
      }"
    >
      <span class="mask" :class="`bg-gradient-${background}`"></span>
      <div 
        class="card-body position-relative z-index-1 p-4"
        :class="{ 'my-1': authorized} "
      >
        <p class="text-white"><strong>Type :</strong> {{ toolType }}</p>
        <p class="text-white"><strong>Marque :</strong> {{ tool.marque }}
          <i v-if="authorized" class="material-icons ms-1 fs-5 cursor-pointer" @click="editMarque">
            edit
          </i>
        </p>
        <p class="text-white mb-0">
          <strong>Programme :</strong> 
          {{ tool.programme }}
          <i v-if="authorizedProgramme" class="material-icons ms-1 fs-5 cursor-pointer" @click="editProgramme">
            edit
          </i>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "OutilCard",
  props: {
    tool: {
      type: Object,
      id: Number,
      ref: String,
      marque: String,
      programme: String,
    },
    toolType: {
      type: String
    }
  },
  data() {
    return {
      background: "dark"
    }
  },
  emits: ['update'],
  computed: {
    ...mapGetters(['role']),
    authorized() {
      return this.role === 'administrateur' || this.role === 'magasin'
    },
    authorizedProgramme() {
      return this.role === 'administrateur' || this.role === 'operateur'
    }
  },
  methods: {
    async editMarque() {
      const { value: marque } = await this.$swal.fire({
        title: `Modifier la marque de l'outil`,
        input: 'text',
        inputValue: this.tool.marque,
        inputPlaceholder: 'Saisissez la marque de l\'outil',
        inputValidator: (value) => {
          if (!value) {
            return 'Veuillez saisir une marque valide.'
          }
        }
      })
      
      if (marque && marque != this.tool.marque) {
        const data = {
          data: {
            marque: marque
          }
        }
        this.$emit('update', data)
      }
    },
    async editProgramme() {
      const { value: programme } = await this.$swal.fire({
        title: `Modifier le programme de l'outil`,
        input: 'text',
        inputValue: this.tool.programme,
        inputPlaceholder: 'Saisissez le programme de l\'outil',
        inputValidator: (value) => {
          if (!value) {
            return 'Veuillez saisir un programme valide.'
          }
        }
      })
      
      if (programme && programme != this.tool.programme) {
        const data = {
          data: {
            programme: programme
          }
        }
        this.$emit('update', data)
      }
    }
  }
};
</script>
