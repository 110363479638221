<template>
  <div class="card mt-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="my-2">Opération spécifique</h6>
        </div>
        <div class="col-6 text-end">
          <material-button 
            v-show="operation !== operation_specifique && authorized"
            color="success"
            variant="gradient"
            @click="update"
          >
            <!-- <i class="fas fa-check me-2"></i> -->
            Enregistrer
          </material-button>
        </div>
      </div>
    </div>
    <div class="card-body p-3">
      <div class="row">
        <div class="col-12 mb-md-0 mb-4">
          <MaterialTextarea
            v-if="authorized"
            id="specific-operation"
            placeholder="Saisissez l'opération spécifique à appliquer." 
            :value="operation"
            @update:value="(newValue) => (operation = newValue)"
          />
          <span v-else class="text">{{ operation }}</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import { mapGetters } from "vuex";

export default {
  name: "SpecificOperation",
  components: {
    MaterialButton,
    MaterialTextarea,
  },
  props: {
    operation_specifique: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      operation: ''
    }
  },
  computed: {
    ...mapGetters(["role"]),
    authorized() {
      return this.role === 'administrateur' || this.role === 'magasin'
    },
  },
  mounted() {
    this.operation = this.operation_specifique
  },
  emits: ['update'],
  methods: {
    update() {
      if (this.operation !== this.operation_specifique) {
        const data = {
          data: {
            operation_specifique: this.operation
          }
        }
        this.$emit('update', data)
      }
    }
  }
};
</script>
