<template>
  <div class="card">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">État</h6>
        </div>
      </div>
    </div>
    <div class="card-body p-3">
      <div class="row">
        <div class="col-md-6 col-xl-3 mb-xl-0 mb-4">
          <div
            class="card card-body border card-plain border-radius-lg d-flex align-items-center justify-content-between flex-row"
          >
            <h6 class="mb-0">
              HS
            </h6>
            <material-switch
              v-if=" role == 'operateur' || role == 'administrateur'"
              id="hs"
              name="hs"
              :checked="state"
              :modelValue="state"
              @update:modelValue="updateState"
            />
            <span v-else-if="hs" class="text">OUI</span>
            <span v-else class="text">NON</span>
          </div>
        </div>
        <div class="col-md-6 col-xl-4 mb-xl-0 mb-4" v-if=" role == 'magasin' || role == 'administrateur'">
          <div
            class="card card-body border card-plain border-radius-lg d-flex align-items-center justify-content-between flex-row"
          >
            <h6 v-if="disponible" class="mb-0">
              Dispo Livraison
            </h6>
            <h6 v-else class="mb-0">
              Dispo Atelier
            </h6>
            <material-switch 
              id="disponible"
              name="disponible"
              :checked="dispo"
              :modelValue="dispo"
              @update:modelValue="updateDispo"
            />
          </div>
        </div>
        <div class="col-md-12 col-xl-5 mb-xl-0 mb-4">
          <div
            class="card card-body border card-plain border-radius-lg d-flex align-items-center justify-content-between flex-row"
          >
            <h6 class="mb-0">
              Jour de tournée
            </h6>
            <span v-if="jour" class="text">{{ jour.toUpperCase() }}</span>
            <span v-else>Jour non défini</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import { mapGetters } from "vuex";

export default {
  name: "EtatOutil",
  components: {
    MaterialSwitch,
  },
  props: {
    hs: {
      type: Boolean,
      default: false
    },
    disponible: {
      type: Boolean,
      default: false
    },
    jour: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      state: false,
      dispo: false
    }
  },
  computed: {
    ...mapGetters(['role']),
  },
  mounted() {
    this.state = this.hs
    this.dispo = this.disponible
  },
  methods: {
    updateState(state) {
      this.state = state
      const data = {
        data: {
          hs: this.state
        }
      }
      this.$emit('update', data)
    },
    updateDispo(dispo) {
      this.dispo = dispo
      const data = {
        data: {
          disponible: this.dispo
        }
      }
      this.$emit('update', data)
    }
  }
};
</script>
