<template>
  <td>
    <p class="px-3 text-sm font-weight-bold mb-0">
      {{ client.attributes.crm_code }}
    </p>
  </td>
  <td>
    <p class="px-2 text-sm font-weight-bold mb-0">
      {{ client.attributes.nom }}
    </p>
  </td>
  <td>
    <p class="px-2 text-sm font-weight-bold mb-0">
      {{ commercial }}
    </p>
  </td>
  <td>
    <p class="px-2 text-sm font-weight-bold mb-0">
      {{ client.attributes.jour }}
    </p>
  </td>
  <td>
    <Popup :popup="popup" :client="client" />
    <material-button 
      v-if="role === 'administrateur'"
      color="danger"
      size="sm"
      class="ms-2"
      @click="clientDelete"
      >Supprimer</material-button
    >
  </td>
</template>
  
<script>
import MaterialButton from "@/components/MaterialButton.vue";
import Popup from "@/components/Popup.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    MaterialButton,
    Popup,
  },
  name: "ClientLine",
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      popup: {
        title: "Modifier client",
        popuptype: "updateCustomer",
        btn: {
          color: "success",
          size: "sm",
          class: "ms-2",
        },
      },
    };
  },
  computed: {
    ...mapGetters(['role']),
    commercial() {
      return this.client.attributes.commercial.data.attributes.nom;
    },
  },
  methods: {
    ...mapActions(["deleteCustomer"]),
    clientDelete() {
      this.$swal
        .fire({
          icon: "danger",
          title: `Supprimer ${this.client.attributes.nom} définitivement?`,
          showDenyButton: true,
          denyButtonText: "Annuler",
          confirmButtonText: "Confirmer",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteCustomer(this.client.id)
              .then((response) => {
                this.$swal.fire({
                  position: "center-center",
                  icon: "success",
                  title: `Client ${response.data.data.attributes.nom} supprimé avec succès`,
                  showConfirmButton: false,
                  timer: 1000,
                });
              })
              .catch((error) => {
                console.log(error);
                this.$swal(
                  "Erreur",
                  "Impossible de supprimer l'utilisateur",
                  "error"
                );
              });
          }
        });
    },
  },
};
</script>
  
  <style scoped>
.btn-success {
  width: 100px;
}
</style>