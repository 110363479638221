<template>
  <div class="row">
    <div class="col-12 mx-auto text-center">
      <material-button
        @click="toggleFilterCrm"
        class="mx-1"
        :color="colorCrm"
      >Recherche par code</material-button>
      
      <material-button
        @click="toggleFilterName"
        class="mx-1"
        :color="colorName"
      >Recherche par nom</material-button>
    </div>
    <div class="col-12 col-lg-10 my-3 mx-auto">
      <material-input
        v-if="queryType"
        id="crm_code"
        type="text"
        label="Code CRM"
        name="crm_code"
        :maxLength="'6'"
        :modelValue="queryCrm"
        @update:modelValue="(newValue) => (queryCrm = newValue)"
      />
      <material-input
        v-else
        id="query"
        type="text"
        label="Nom"
        name="nom"
        :modelValue="queryName"
        @update:modelValue="(newValue) => (queryName = newValue)"
      />
    </div>
    <div class="col-12">
      <div v-if="clients == null"></div>
      <p v-else-if="clients.length == 0 ">Aucun résultat ne correspond.</p>
      <div v-else-if="tool" class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <tr v-for="c in clients" :key="c.id">
            <td>
              <p class="px-3 text-sm font-weight-bold mb-0">
                {{ c.attributes.crm_code }}
              </p>
            </td>
            <td>
              <p class="px-3 text-sm font-weight-bold mb-0">
                {{ c.attributes.nom }}
              </p>
            </td>
            <td>
              <material-button
                color="success"
                size="sm"
                class="ms-2"
                @click="selectCustomer(c.id)"
                >Sélectionner</material-button
              >
            </td>
          </tr>
        </table>
      </div>
      <div v-else-if="list" class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Code CRM
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                Nom
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                Commercial
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                Jour
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="c in clients" :key="c.id">
              <client-line :client="c" />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import ClientLine from "@/components/clients/ClientLine.vue"
import { mapActions } from "vuex";

export default {
  components: {
    MaterialInput,
    MaterialButton,
    ClientLine
  },
  props: {
    tool: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      clients: null,

      colorCrm: 'info',
      colorName: 'link',
      
      queryCrm: '',
      codeLength: 6,
      queryName: '',
      minNameLength: 3,

      queryType: true,

      loading: false,
    };
  },
  name: "ClientSearch",
  emits: ["select-customer"],
  watch: {
    queryCrm: function (val) {
      if (val.length == this.codeLength) {
        this.search(`?filters[crm_code][$eq]=${this.queryCrm}&populate[0]=commercial`);
      } else this.clients = null
    },
    queryName: function (val) {
      if (val.length >= this.minNameLength) {
        this.search(`?filters[nom][$containsi]=${this.queryName}&populate[0]=commercial`);
      } else this.clients = null
    },
  },
  methods: {
    ...mapActions(["getCustomers"]),
    selectCustomer(id) {
      const data = {
        data: {
          client: id
        }
      }
      this.$emit('select-customer', data);
    },
    toggleFilterCrm() {
      this.colorCrm = 'info'
      this.colorName = 'link'
      this.queryName = ''
      this.queryType = true
      this.clients = null
    },
    toggleFilterName() {
      this.colorCrm = 'link'
      this.colorName = 'info'
      this.queryCrm = ''
      this.queryType = false
      this.clients = null
    },
    search(query) {
      this.getCustomers({ populate: query })
      .then((response) => {
          this.clients = response.data.data
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>