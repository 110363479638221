<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 pb-3">
        <Popup :popup="popup" />
      </div>
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-info shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">Recherche clients</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <customer-search :list="true"></customer-search>
            <!-- <div v-if="!clients" class="text-center pb-2">
              Chargement...
            </div>
            <div v-else-if="clients.length == 0" class="text-center pb-2">
              Aucun client existant.
            </div>
            <div v-else class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Code CRM
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                      Nom
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                      Commercial
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                      Jour
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="c in clients" :key="c.id">
                    <client-line :client="c" />
                  </tr>
                </tbody>
              </table>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "@/components/Popup.vue";
// import ClientLine from "@/components/clients/ClientLine.vue"
import { mapState, mapActions } from 'vuex';
import CustomerSearch from '@/components/CustomerSearch.vue';

export default {
  components: { 
    Popup,
    // ClientLine,
    CustomerSearch
  },
  name: "Clients",
  data() {
    return {
      popup: {
        title: "Créer client",
        popuptype: 'createCustomer',
      }
    }
  },
  computed: {
    ...mapState(['clients']),
  },
  mounted() {
    this.getCustomers({
      populate: '?populate[0]=commercial'
    }).catch(error => {
      console.log(error)
      this.$swal("Erreur", "Impossible de récupérer la liste des clients", "error")
    })
  },
  methods: {
    ...mapActions(["getCustomers"])
  }
};
</script>

  