<template>
    <material-button
      @click="modalToggle"
      :color="btn.color"
      :size="btn.size"
      :class="btn.class"
    >{{ popup.title }}</material-button>
    <div
      class="modal fade" 
      :class="{ show: active, 'd-block': active }"
      id="users" 
      data-bs-backdrop="static" 
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="users"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5">{{ popup.title }}</h1>
            <button
              type="button"
              class="btn-close"
              @click="modalToggle"
              aria-label="Close"
            >
              <i class="material-icons-round fs-5">close</i>
            </button>
          </div>
          <user-create v-if=" popup.popuptype == 'createUser' " @modaltoggle="modalToggle" />
          <user-update v-if=" popup.popuptype == 'updateUser' " @modaltoggle="modalToggle" :current="user" />
          <commercial-create v-if=" popup.popuptype == 'createCommercial' " @modaltoggle="modalToggle" />
          <commercial-update v-if=" popup.popuptype == 'updateCommercial' " @modaltoggle="modalToggle" :current="sale"/>
          <client-create v-if="popup.popuptype == 'createCustomer' " @modaltoggle="modalToggle" />
          <client-update v-if="popup.popuptype == 'updateCustomer' " @modaltoggle="modalToggle" :current="client" />
        </div>
      </div>
    </div>
    <div v-if="active" class="modal-backdrop fade show"></div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import UserCreate from '@/components/users/UserCreate.vue';
import UserUpdate from '@/components/users/UserUpdate.vue';
import CommercialCreate from '@/components/commercials/CommercialCreate.vue';
import CommercialUpdate from '@/components/commercials/CommercialUpdate.vue';
import ClientCreate from '@/components/clients/ClientCreate.vue';
import ClientUpdate from '@/components/clients/ClientUpdate.vue';

export default {
  components: {
    MaterialButton,
    UserCreate,
    UserUpdate,
    CommercialCreate,
    CommercialUpdate,
    ClientCreate,
    ClientUpdate,
  },
  props: {
    popup: {
      type: Object,
      required: true,
    },
    user: {
      type: Object
    },
    sale: {
      type: Object
    },
    client: {
      type: Object
    }
  },
  data() {
    return {
      active: false,
      btn: {
        color: "success",
        size: "md",
        class: "",
      }
    }
  },
  name: "UserPopup",
  mounted() {
    if (this.popup.btn) {
      this.btn.color = this.popup.btn.color
      this.btn.size = this.popup.btn.size
      this.btn.class = this.popup.btn.class
    }
  },
  methods: {
    modalToggle() {
      const body = document.querySelector("body")
      this.active = !this.active
      this.active ? body.classList.add("modal-open") : body.classList.remove("modal-open")
    },
  }
}

</script>

<style>
.modal {
    z-index: 10000;
}
.btn-close i.material-icons-round {
  color: black !important;
}
</style>