import { createRouter, createWebHistory } from "vue-router";
import Search from "../views/Search.vue";
// import Dashboard from "../views/Dashboard.vue";
// import Outils from "../views/outils/Outils.vue";
import Outil from "../views/outils/Outil.vue";
import Remarques from "../views/Remarques.vue";
import Commercials from "../views/Commercials.vue";
import Clients from "../views/Clients.vue";
import Users from "../views/Users.vue";
import SignIn from "../views/SignIn.vue";
import NotFound from "../views/NotFound.vue";
import store from '../store'

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/qr-code-scanner",
  },
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   component: Dashboard,
  // },
  {
    path: "/qr-code-scanner",
    name: "Scanner QR Code",
    component: Search,
  },
  // {
  //   path: "/outils",
  //   name: "Outils",
  //   component: Outils,
  // },
  {
    path: "/outils/:id",
    name: "Fiche outil",
    component: Outil,
  },
  {
    path: "/remarques",
    name: "Remarques spéciales",
    component: Remarques,
    meta: {
      requireAuth: ['administrateur']
    }
  },
  {
    path: "/commerciaux",
    name: "Commerciaux",
    component: Commercials,
    meta: {
      requireAuth: ['administrateur', 'magasin']
    }
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    meta: {
      requireAuth: ['administrateur', 'magasin']
    }
  },
  {
    path: "/users",
    name: "Utilisateurs",
    component: Users,
    meta: {
      requireAuth: ['administrateur']
    }
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: '404', 
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'SignIn' && !store.state.authUser.auth) next({ name: 'SignIn' })
  else if (to.meta.requireAuth) {
    if (!to.meta.requireAuth.includes(store.getters.role)) next({ name: '404' })
    else next()
  } else next()
})

export default router;
