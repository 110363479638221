<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 pb-3">
        <Popup :popup="popup" />
      </div>
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-info shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">Commerciaux</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div v-if="!commercials" class="text-center pb-2">
              Chargement...
            </div>
            <div v-else-if="commercials.length == 0" class="text-center pb-2">
              Aucun commercial existant.
            </div>
            <div v-else class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Nom
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="s in commercials" :key="s.id">
                    <commercial-line :sale="s" />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "@/components/Popup.vue";
import CommercialLine from "@/components/commercials/CommercialLine.vue"
import { mapState, mapActions } from 'vuex';

export default {
  components: { 
    Popup,
    CommercialLine
  },
  name: "Commercials",
  data() {
    return {
      popup: {
        title: "Créer commercial",
        popuptype: 'createCommercial',
      }
    }
  },
  computed: {
    ...mapState(['commercials']),
  },
  mounted() {
    this.getCommercials()
    .catch(error => {
      console.log(error)
      this.$swal("Erreur", "Impossible de récupérer la liste des commerciaux", "error")
    })
  },
  methods: {
    ...mapActions(["getCommercials"])
  }
};
</script>
