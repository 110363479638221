<template>
    <div class="container-fluid not-found">
      Oups, cette page ne semble pas exister
    </div>
  </template>
  
  <script>  
  export default {
    name: "NotFound",
  };
  </script>