import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    authUser: {
      auth: false,
      jwt: '',
      infos: {}
    },
    api: {
      url: 'https://strapi-production-1c12.up.railway.app/api'
    },
    roles: [
      {
        id: 3,
        name: "Gestionnaire",
        slug: "administrateur",
      },
      {
        id: 5,
        name: "Magasin",
        slug: "magasin",
      },
      {
        id: 4,
        name: "Opérateur",
        slug: "operateur",
      },
    ],
    users: [],
    commercials: [],
    clients: [],
    outils: [],

    // Config
    hideConfigButton: true,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    color: "info",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: false,
    isDarkMode: false,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
  },
  
  getters: {
    role( state ) {
      if (state.authUser.auth) return state.authUser.infos.role.type
      else return 'public'
    }
  },

  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    color(state, payload) {
      state.color = payload;
    },

    // Users
    setUsers(state, payload) {
      state.users = payload
    },
    addUser(state, payload) {
      state.users.push(payload)
    },
    updateUser(state, payload) {
      const id = payload.id
      let index = state.users.findIndex(obj => obj.id === id);
      if (index !== -1) {
        state.users[index] = payload
      }
    },
    removeUser(state, payload) {
      const id = payload.id
      let index = state.users.findIndex(obj => obj.id === id);
      if (index !== -1) {
        state.users.splice(index, 1);
      }
    },

    // Commercials
    setCommercials(state, payload) {
      state.commercials = payload.data
    },
    addCommercial(state, payload) {
      state.commercials.push(payload)
    },
    updateCommercial(state, payload) {
      const id = payload.id
      let index = state.commercials.findIndex(obj => obj.id === id);
      if (index !== -1) {
        state.commercials[index] = payload
      }
    },
    removeCommercial(state, payload) {
      const id = payload.id
      let index = state.commercials.findIndex(obj => obj.id === id);
      if (index !== -1) {
        state.commercials.splice(index, 1);
      }
    },

    // Clients
    setCustomers(state, payload) {
      state.clients = payload.data
    },
    addCustomer(state, payload) {
      state.clients.push(payload)
    },
    updateCustomer(state, payload) {
      const id = payload.id
      let index = state.clients.findIndex(obj => obj.id === id);
      if (index !== -1) {
        state.clients[index] = payload
      }
    },
    removeCustomer(state, payload) {
      const id = payload.id
      let index = state.clients.findIndex(obj => obj.id === id);
      if (index !== -1) {
        state.clients.splice(index, 1);
      }
    },

    // Outils
    setTools(state, payload) {
      // for (let o of payload.data) 
      //   if ( state.outils.findIndex(obj => obj.id === o.id) === -1 )
      //     state.outils.push(o)
      state.outils = payload.data
    },
    addTool(state, payload) {
      state.outils.push(payload)
    },
    updateTool(state, payload) {
      const id = payload.id
      let index = state.outils.findIndex(obj => obj.id === id);
      if (index !== -1) {
        state.outils[index] = payload
      }
    },
    removeTool(state, payload) {
      const id = payload.id
      let index = state.outils.findIndex(obj => obj.id === id);
      if (index !== -1) {
        state.outils.splice(index, 1);
      }
    },
  },

  actions: {
    setColor({ commit }, payload) {
      commit("color", payload);
    },

    // Users
    getUsers({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios
          .get(state.api.url + "/users?populate=role", {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('setUsers', response.data)
            resolve('ok')
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    createUser({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        payload.creator = state.authUser.infos.id
        axios
          .post(state.api.url + "/users", payload , {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('addUser', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    updateUser({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        payload.user.creator = state.authUser.infos.id
        axios
          .put(state.api.url + "/users/"+payload.id, payload.user, {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('updateUser', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    deleteUser({ commit, state}, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(state.api.url + "/users/" + payload, {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('removeUser', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },

    // Commercials
    getCommercials({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios
          .get(state.api.url + "/commercials", {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('setCommercials', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    createCommercial({ commit, state}, payload) {
      return new Promise((resolve, reject) => {
        payload.data.creator = state.authUser.infos.id
        axios
          .post(state.api.url + "/commercials", payload , {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('addCommercial', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    updateCommercial({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(state.api.url + "/commercials/"+payload.id, payload.data, {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('updateCommercial', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    deleteCommercial({ commit, state}, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(state.api.url + "/commercials/" + payload, {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('removeCommercial', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },

    // Clients
    getCustomers({ commit, state }, payload = {}) {
      return new Promise((resolve, reject) => {
        let populate = ''
        if (payload.populate) populate = payload.populate
        axios
          .get(state.api.url + "/clients"+populate, {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('setCustomers', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    createCustomer({ state}, payload) {
      return new Promise((resolve, reject) => {
        payload.data.creator = state.authUser.infos.id
        axios
          .post(state.api.url + "/clients", payload , {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            // commit('addCustomer', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    updateCustomer({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(state.api.url + "/clients/"+payload.id, payload.data, {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            // commit('updateCustomer', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    deleteCustomer({ commit, state}, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(state.api.url + "/clients/" + payload, {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('removeCustomer', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },

    // Outils
    getTool({ commit, state }, payload = {}) {
      return new Promise((resolve, reject) => {
        let custom = ''
        if (payload.custom) custom = payload.custom
        axios
          .get(state.api.url + "/outils"+custom, {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('addTool', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    getTools({ commit, state }, payload = {}) {
      return new Promise((resolve, reject) => {
        let custom = ''
        if (payload.custom) custom = payload.custom
        axios
          .get(state.api.url + "/outils"+custom, {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('setTools', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    createTool({ commit, state}, payload) {
      return new Promise((resolve, reject) => {
        payload.data.creator = state.authUser.infos.id
        axios
          .post(state.api.url + "/outils", payload , {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('addTool', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    updateTool({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(state.api.url + "/outils/"+payload.id, payload.data, {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('updateTool', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    deleteTool({ commit, state}, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(state.api.url + "/outils/" + payload, {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            commit('removeTool', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },

    // Opérations
    getOperations({ state }, payload = {}) {
      return new Promise((resolve, reject) => {
        let custom = ''
        if (payload.custom) custom = payload.custom
        axios
          .get(state.api.url + "/operations"+custom, {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    createOperation({ state }, payload) {
      return new Promise((resolve, reject) => {
        payload.data.operateur = state.authUser.infos.id
        axios
          .post(state.api.url + "/operations", payload , {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },

    // Remarques
    getRemarques({ state }, payload = {}) {
      return new Promise((resolve, reject) => {
        let custom = ''
        if (payload.custom) custom = payload.custom
        axios
          .get(state.api.url + "/remarques"+custom, {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
    createRemarque({ state }, payload) {
      return new Promise((resolve, reject) => {
        payload.data.user = state.authUser.infos.id
        axios
          .post(state.api.url + "/remarques", payload , {
            headers: {
              Authorization: `Bearer ${state.authUser.jwt}`,
            },
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          });
      })
    },
  }
});
