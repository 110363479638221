<template>
  <td>
    <p class="px-3 text-sm font-weight-bold mb-0">
      {{ user.username }}
    </p>
  </td>
  <td>
    <p class="text-sm font-weight-bold mb-0">{{ user.email }}</p>
  </td>
  <td>
    <p class="text-sm font-weight-bold mb-0">
      {{ user.role.name }}
    </p>
  </td>
  <td>
    <Popup :popup="popup" :user="user" />
    <material-button
      v-if="user.id !== owner"
      color="danger"
      size="sm"
      class="ms-2"
      @click="delUser"
      >Supprimer</material-button
    >
    <!-- @click="delUser(u)" -->
  </td>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import Popup from "@/components/Popup.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { 
    MaterialButton,
    Popup
  },
  name: "UserLine",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      popup: {
        title: "Modifier utilisateur",
        popuptype: 'updateUser',
        btn: {
          color: "success",
          size: "sm",
          class: "ms-2",
        }
      }
    }
  },
  computed: {
    ...mapState(['authUser']),
    owner() {
      return this.authUser.infos.id
    }
  },
  methods: {
    ...mapActions(['deleteUser']),
    delUser() {
      this.$swal
        .fire({
          icon: "danger",
          title: `Supprimer ${this.user.username} définitivement?`,
          showDenyButton: true,
          denyButtonText: "Annuler",
          confirmButtonText: "Confirmer",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteUser(this.user.id)
            .then(response => {
              this.$swal.fire({
                position: 'center-center',
                icon: 'success',
                title: `Utilisateur ${response.data.username} supprimé avec succès`,
                showConfirmButton: false,
                timer: 1000
              })
            })
            .catch((error) => {
              console.log(error);
              this.$swal(
                "Erreur",
                "Impossible de supprimer l'utilisateur",
                "error"
              );
            })
          }
        });
    },
  },
};
</script>

<style scoped>
  .btn-success {
    width: 100px;
  }
</style>