<template>
  <div class="card mb-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="my-2">Remarques pour facturation</h6>
        </div>
        <div class="col-6 text-end">
          <material-button 
            v-if="authorized"
            color="success"
            @click="addReq"
          >Ajouter
          </material-button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0 p-3">
      <span v-if="reqs === null" class="text">Chargement des remarques en cours...</span>
      <span v-else-if="reqs.length === 0" class="text">Aucune remarque existante.</span>
      <ul v-else class="list-group">
        <li v-for="r in reqs" :key="r.id"
          class="list-group-item border-0 d-flex p-3 my-2 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm">
              <span class="me-sm-2">
                {{ new Date(r.attributes.createdAt).toLocaleString('fr-FR') }}
              </span>
              -
              <span class="text-dark ms-sm-2"
                >{{ r.attributes.user.data.attributes.username }}</span
              >
            </h6>
            <span class="mb-2 text-sm">
              {{ r.attributes.remarque }}
            </span>
          </div>
          <div class="ms-auto text-end">
            <!-- <a class="btn btn-link text-dark px-3 mb-0" href="javascript:;">
              <i class="fas fa-pencil-alt text-dark me-2" aria-hidden="true"></i
              >Edit
            </a> -->
            <!-- <a
              class="btn btn-link text-danger text-gradient px-3 mb-0"
              href="javascript:;"
            >
              <i class="far fa-trash-alt me-2" aria-hidden="true"></i>Delete
            </a> -->
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    MaterialButton,
  },
  name: "RemarquesCard",
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      reqs: null,
      message: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters(["role"]),
    authorized() {
      return this.role === 'administrateur' || this.role === 'magasin'
    }
  },
  mounted() {
    this.getReqs()
  },
  methods: {
    ...mapActions(["getRemarques", "createRemarque"]),
    getReqs() {
      this.loading = true
      this.getRemarques({ custom: `/?populate[0]=user&filters[outil][id][$eq]=${this.id}&sort[0]=createdAt:desc` })
        .then((response) => {
          this.message = ''
          this.reqs = response.data.data
          this.loading = false
        })
        .catch((error) => {
          console.log(error);
          this.message = 'Une erreur est survenue lors de la récupération des remarques.'
          this.loading = false
        });
    },
    async addReq() {
      const { value: remarque } = await this.$swal.fire({
        title: `Ajouter une remarque`,
        input: 'textarea',
        inputPlaceholder: 'Contenu de la remarque',
        inputValidator: (value) => {
          if (!value) {
            return 'Veuillez saisir une remarque.'
          }
        }
      })

      if (remarque) {
        const payload = {
          data: {
            remarque: remarque,
            outil: this.id
          }
        }
        this.createRemarque(payload)
        .then(() => {
          this.$swal
            .fire({
              position: "top-end",
              icon: "success",
              title: "Remarque ajourée avec succès",
              showConfirmButton: false,
              timer: 1000,
            })
            this.getReqs()
          })
        .catch((error) => {
          console.log(error);
          this.$swal("Erreur", "Oups, une erreur est survenue.", "error");
        });
      }
    }
  }
};
</script>
