<template>
  <div
    class="page-header align-items-start min-vh-100"
    style="
      background-image: url('https://images.unsplash.com/photo-1509460364839-ef5b594e0ee8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80');
    "
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-info shadow-info border-radius-lg py-3 pe-1"
              >
                <h4 class="text-white font-weight-bolder text-center mt-2 pb-2 mb-0">
                  Identification
                </h4>
              </div>
            </div>
            <div class="card-body">
              <!-- <form role="form" class="text-start mt-3"> -->
                <div class="mb-3">
                  <material-input 
                    id="identifiant"
                    type="text"
                    label="Identifiant"
                    name="identifiant"
                    :modelValue="identifiant"
                    @update:modelValue="newValue => identifiant = newValue"
                  />
                </div>
                <div class="mb-3 d-flex align-items-center justify-content-between">
                  <material-input
                    id="password"
                    :type="passType"
                    label="Password"
                    name="password"
                    :modelValue="pass"
                    @update:modelValue="newValue => pass = newValue"
                  />
                  &nbsp;
                  <i
                    class="material-icons-round cursor-pointer"
                    @click="toggleVisibility"
                    >{{ iconPass }}</i
                  >
                </div>
                <!-- <material-switch id="rememberMe" name="rememberMe"
                  >Se souvenir de moi</material-switch
                > -->
                <div class="text-center">
                  <material-button
                    v-if="!loading"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                    fullWidth
                    @click="login"
                    >Connexion</material-button
                  >
                  <p v-else class="pt-4 pb-2">Connexion en cours...</p>
                </div>
              <!-- </form> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
// import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";
import axios from 'axios';

export default {
  name: "sign-in",
  components: {
    MaterialInput,
    // MaterialSwitch,
    MaterialButton,
  },
  data() {
    return {
      identifiant: '',
      pass: '',
      loading: false,
      visiblePassword: false,
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  computed: {
    passType() {
      if (this.visiblePassword) return "text";
      else return "password";
    },
    iconPass() {
      if (this.visiblePassword) return "visibility_off";
      else return "visibility";
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    toggleVisibility() {
      this.visiblePassword = !this.visiblePassword;
    },
    login() {
      this.loading = true
      axios
      .post(this.$store.state.api.url+'/auth/local', {
        identifier: this.identifiant,
        password: this.pass
      })
      .then(response => {
        this.$store.state.authUser.jwt = response.data.jwt
        this.getUser()
      })
      .catch(error => {
        if(error.response.status === 400) this.$swal('Erreur', 'Identifiants invalides', 'error')
        else console.log(error)
        this.loading = false
      });
    },
    getUser() {
      axios
      .get(this.$store.state.api.url+'/users/me?populate=role',
        {
          headers: {
            'Authorization': `Bearer ${this.$store.state.authUser.jwt}` 
          }
        })
      .then(response => {
        this.$store.state.authUser.infos = response.data
        this.$store.state.authUser.auth = true
        // TODO
        sessionStorage.user = JSON.stringify(this.$store.state.authUser);
        this.loading = false
        if(response.data.role.type === 'operateur' || response.data.role.type === 'magasin' ) this.$router.push('/qr-code-scanner')
        else this.$router.push('/')
      })
      .catch((error) => {
        console.log(error)
        this.$swal('Erreur', 'Impossible de récupérer le compte', 'error')
        this.loading = false
      });
    }
  },
};
</script>
