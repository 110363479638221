<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 pb-3">
        <Popup :popup="popup" />
      </div>
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div class="bg-gradient-info shadow-success border-radius-lg pt-4 pb-3 d-flex align-items-center justify-content-between">
              <h6 class="text-white text-capitalize ps-3">Utilisateurs</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div v-if="!users" class="text-center pb-2">Chargement...</div>
            <div v-else-if="users.length == 0" class="text-center pb-2">
              Aucun utilisateur existant.
            </div>
            <div v-else class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Identifiant
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                      E-mail
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                      Rôle
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="u in users" :key="u.id">
                    <user-line :user="u" />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "@/components/Popup.vue";
import UserLine from "@/components/users/UserLine.vue"
import { mapState, mapActions } from "vuex";

export default {
  components: { 
    Popup,
    UserLine,
  },
  name: "Users",
  data() {
    return {
      popup: {
        title: "Créer Utilisateur",
        popuptype: 'createUser',
      }
    };
  },
  mounted() {
    this.getUsers()
    .catch(error => {
      console.log(error)
      this.$swal("Erreur", "Impossible de récupérer la liste", "error")
    })
  },
  computed: {
    ...mapState(['users']),
  },
  methods: {
    ...mapActions(['getUsers']),
  },
};
</script>

<style>
.form-select {
  border: 1px solid lightgrey !important;
}
.form-select:focus,
.form-select.is-valid {
  box-shadow: 0 0 0 1px #1a73e8 !important;
  border-color: #1a73e8 !important;
}
</style>
