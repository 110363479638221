<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12 col-lg-6 col-xl-6">
        <h5 class="mt-1 mb-2">
          Rechercher les opérations incluant une remarque :
        </h5>
      </div>
      <div class="col-12 col-lg-6 col-xl-6">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 mb-3 mb-md-0">
            <material-input
              id="start"
              type="date"
              label="De"
              name="start"
              :modelValue="start"
              :maxDate="max"
              @update:modelValue="(newValue) => (start = newValue)"
            />
          </div>
          <div class="col-12 col-sm-6 col-md-4 mb-3 mb-md-0">
            <material-input
              id="end"
              type="date"
              label="À"
              name="end"
              :modelValue="end"
              :maxDate="max"
              @update:modelValue="(newValue) => (end = newValue)"
            />
          </div>
          <div class="col-12 col-sm-6 col-md-4 mb-3 mb-md-0 mx-auto">
            <material-button @click="search" :fullWidth="true" color="success"
              >Rechercher</material-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-info shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white ps-3">Opérations avec remarques</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div v-if="!remarques" class="text-center pb-2">Chargement...</div>
            <div v-else-if="remarques.length == 0" class="text-center pb-2">
              Aucune opération avec remarque spéciale sur cette période.
            </div>
            <div v-else class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Remarque
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3"
                    >
                      Outil
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3"
                    >
                      Opérateur
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="r in remarques" :key="r.id">
                    <td v-if="r.attributes.remarque != ''">
                      <p class="px-3 text-sm font-weight-bold mb-0">
                        {{ r.attributes.remarque }}
                      </p>
                    </td>
                    <td v-if="r.attributes.remarque != ''">
                      <p class="px-2 text-sm font-weight-bold mb-0">
                        <router-link
                          :to="{ name: 'Fiche outil', params:{ id: r.attributes.outil.data.id}}"
                        >
                        {{ r.attributes.outil.data.attributes.ref }}
                        </router-link>
                      </p>
                    </td>
                    <td v-if="r.attributes.remarque != ''">
                      <p class="px-2 text-sm font-weight-bold mb-0">
                        {{ r.attributes.operateur.data.attributes.username }}
                      </p>
                    </td>
                    <td v-if="r.attributes.remarque != ''">
                      <p class="px-2 text-sm font-weight-bold mb-0">
                        {{ new Date(r.attributes.createdAt).toLocaleString('fr-FR') }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import { mapActions } from "vuex";

export default {
  components: {
    MaterialInput,
    MaterialButton,
  },
  name: "Remarques",
  data() {
    return {
      start: "",
      end: "",
      max: "",

      remarques: null,
    };
  },
  computed: {
    isoStart() {
      if (this.start != '') {
        const start = new Date(this.start)
        return start.toISOString()
      } else return ''
    },
    isoEnd() {
      if (this.end != '') {
        const end = new Date(this.end)
        end.setDate(end.getDate() + 1)
        return end.toISOString()
      } else return ''
    }
  },
  mounted() {
    const today = new Date();
    const todayFormat = today.toISOString().split("T")[0];
    this.start = todayFormat;
    this.end = todayFormat;
    this.max = todayFormat;
    this.search();
  },
  methods: {
    ...mapActions(["getOperations"]),
    search() {
      this.getOperations({
        custom: `?filters[$and][0][createdAt][$gte]=${this.isoStart}&filters[$and][1][createdAt][$lte]=${this.isoEnd}&filters[$and][2][remarque][$notnull]=true&populate=*&sort=createdAt`,
      }).then((response) => {
        this.remarques = response.data.data;
      });
    },
  },
};
</script>
