<template>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 my-2">
        <select
          id="selectRole"
          v-model="user.role"
          :class="{ 'is-valid': user.role !== '' }"
          class="form-select w-100 px-3"
          data-style="btn btn-primary btn-round"
        >
          <option disabled value="">Sélectionner un rôle</option>
          <option v-for="r in roles" :value="r.id" :key="r.id">
            {{ r.name }}
          </option>
        </select>
      </div>
      <div class="col-lg-6 my-2">
        <material-input
          id="username"
          type="text"
          label="Identifiant"
          name="username"
          :modelValue="user.username"
          @update:modelValue="(newValue) => (user.username = newValue)"
        />
      </div>
      <div class="col-lg-6 my-2">
        <material-input
          id="email"
          type="email"
          label="Adresse e-mail"
          name="email"
          :modelValue="user.email"
          @update:modelValue="(newValue) => (user.email = newValue)"
        />
      </div>
      <div
        class="col-lg-6 my-2 d-flex align-items-center justify-content-between"
      >
        <material-input
          id="password"
          :type="passType"
          label="Mot de passe"
          name="password"
          :modelValue="user.password"
          @update:modelValue="(newValue) => (user.password = newValue)"
        />
        &nbsp;
        <i
          class="material-icons-round cursor-pointer"
          @click="toggleVisibility"
          >{{ iconPass }}</i
        >
      </div>
      <div class="col-lg-6 my-2">
        <material-button class="w-100" @click="generatePassword"
          >Générer mot de passe</material-button
        >
      </div>
    </div>
  </div>
  <div v-if="!loading" class="modal-footer">
    <button type="button" class="btn btn-secondary" @click="modalToggle">
      Annuler
    </button>
    <button type="button" class="btn btn-primary" @click="userCreate">
      Créer le compte
    </button>
  </div>
  <div v-else class="modal-footer text-center">
    Création de l'utilisateur en cours
  </div>
</template>
  
  <script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapState, mapActions } from "vuex";
// import axios from "axios";

export default {
  components: {
    MaterialInput,
    MaterialButton,
  },
  data() {
    return {
      user: {
        username: "",
        email: "",
        password: "",
        role: "",
      },

      visiblePassword: false,
      loading: false,
    };
  },
  name: "UserCreate",
  computed: {
    ...mapState(["authUser", "api", "roles"]),
    passType() {
      if (this.visiblePassword) return "text";
      else return "password";
    },
    iconPass() {
      if (this.visiblePassword) return "visibility_off";
      else return "visibility";
    },
  },
  emits: ["modaltoggle"],
  methods: {
    // ...mapMutations(["addUser"]),
    ...mapActions(["createUser"]),
    modalToggle() {
      this.$emit("modaltoggle");
    },
    toggleVisibility() {
      this.visiblePassword = !this.visiblePassword;
    },
    generatePassword() {
      const chars =
        "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const passwordLength = 12;
      this.user.password = "";

      for (let i = 0; i <= passwordLength; i++) {
        let randomNumber = Math.floor(Math.random() * chars.length);
        this.user.password += chars.substring(randomNumber, randomNumber + 1);
      }
    },
    userCreate() {
      this.loading = true;
      if (this.user.role == "" || this.user.role == null) {
        this.$swal("Rôle invalide", "Veuillez sélectionner un rôle.", "error");
        return;
      }
      if (this.user.username == "" || this.user.username == null) {
        this.$swal(
          "Identifiant vide",
          "Veuillez saisir un identifiant.",
          "error"
        );
        return;
      }
      if (this.user.email == "" || this.user.email == null) {
        this.$swal(
          "Adresse e-mail incorrecte",
          "Veuillez saisir une adresse e-mail valide.",
          "error"
        );
        return;
      }
      if (this.user.password == "" || this.user.password == null) {
        this.$swal(
          "Mot de passe vide",
          "Veuillez saisir ou générer un mot de passe.",
          "error"
        );
        return;
      }

      this.createUser(this.user)
        .then( (response) => {
          this.modalToggle();
          this.$swal.fire({
            position: "center-center",
            icon: "success",
            title: `Utilistateur ${response.data.username} créé avec succès`,
            showConfirmButton: false,
            timer: 1000,
          });
            this.user.username = ""
            this.user.email = ""
            this.user.password = ""
            this.user.role = ""
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Erreur", "Oups, une erreur est survenue.", "error");
        });
      this.loading = false;
    },
  },
};
</script>