<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
    :class="isAbsolute ? 'mt-4' : 'mt-0'"
  >
    <div class="px-0 px-sm-1 px-md-3 py-1 container-fluid">
      <breadcrumbs class="d-none d-md-block" :currentPage="currentRouteName" :color="color" />
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        id="navbar"
      >
        <div
          class="px-2 d-flex justify-content-middle m-auto w-100"
        >
          <p class="py-2 my-0 ml-auto m-md-auto">Connecté en tant que <strong>{{ user }}</strong>.</p>
        </div>
        <ul class="navbar-nav justify-content-end">
          <!-- <li class="nav-item d-flex px-2 align-items-center">
            <router-link
              :to="{ name: 'Profil' }"
              class="px-0 px-xl-2 nav-link font-weight-bold lh-1"
              :class="color ? color : 'text-body'"
            >
              <i class="material-icons me-sm-1">
                account_circle
              </i>
            </router-link>
          </li> -->
          <!-- <li class="nav-item d-flex pt-2 px-2 align-items-center">
            <p class="ml-auto">Connecté en tant que <strong>{{ user }}</strong>.</p>
          </li> -->
          <li class="nav-item d-xl-none px-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-body lh-1"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
          <li class="nav-item d-flex px-2 align-items-center">
            <span
              class="px-0 px-xl-2 nav-link font-weight-bold lh-1 cursor-pointer"
              :class="color ? color : 'text-body'"
              @click="deconnexion"
            >
              <i class="material-icons me-sm-1">
                logout
              </i>
            </span>
          </li>
          
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
// import MaterialInput from "@/components/MaterialInput.vue";
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapState } from "vuex";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      user: this.$store.state.authUser.infos.username
    };
  },
  props: ["minNav", "color"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),

    toggleSidebar() {
      this.navbarMinimize();
    },

    deconnexion() {
      this.$swal.fire('Déconnexion', 'Souhaitez-vous vous déconnecter?', 'warning')
      .then((response) => {
        if (response.isConfirmed) {
            this.$store.state.authUser = {}
            // localStorage.clear()
            sessionStorage.clear()
            this.$router.push('/sign-in')
        }
      });
    }
  },
  components: {
    Breadcrumbs,
    // MaterialInput,
  },
  computed: {
    ...mapState(["isRTL", "isAbsolute"]),

    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
