<template>
  <div v-if="loaded" class="container-fluid mt-4">
    <div class="row align-items-center">
      <div class="col-12">
        <h3>
          Outil n° <strong>{{ tool.ref }}</strong>
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="row mt-4">
          <div class="col-xl-6 mb-xl-0 mb-4">
            <outil-card :tool="tool" :toolType="toolType.text" @update="update" />
          </div>
          <div class="col-xl-6">
            <div class="row">
              <div class="col-md-6">
                <client-info-card
                  icon="person"
                  title="Client"
                  :client="tool.client.data"
                  @update="update"
                />
              </div>
              <div class="col-md-6">
                <commercial-info-card
                  icon="card_travel"
                  title="Commercial"
                  :client="tool.client.data"
                />
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-4">
            <specific-operation :operation_specifique="tool.operation_specifique" @update="update" />
          </div>
          <div class="col-md-12 mb-4">
            <etat-outil :hs="tool.hs" :disponible="tool.disponible" :jour="jour" @update="update" />
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <caracteristiques-card :id="tool.id" :caracs="tool.caracs" :type="toolType.code" @update="update" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <operations-card :id="tool.id" :type="toolType.code" />
      </div>
      <div class="col-md-6">
        <remarques-card :id="tool.id" />
      </div>
    </div>
  </div>
  <div v-else class="container-fluid mt-4">Chargement...</div>
</template>

<script>
import OutilCard from "@/components/outils/OutilCard.vue";
import ClientInfoCard from "@/components/outils/ClientInfoCard.vue";
import CommercialInfoCard from "@/components/outils/CommercialInfoCard.vue";
import SpecificOperation from "@/components/outils/SpecificOperation.vue";
import EtatOutil from "@/components/outils/EtatOutil.vue";
import CaracteristiquesCard from "@/components/outils/CaracteristiquesCard.vue";
import OperationsCard from "@/components/outils/OperationsCard.vue";
import RemarquesCard from "@/components/outils/RemarquesCard.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "Outil",
  components: {
    OutilCard,
    ClientInfoCard,
    CommercialInfoCard,
    SpecificOperation,
    EtatOutil,
    CaracteristiquesCard,
    OperationsCard,
    RemarquesCard,
  },
  computed: {
    ...mapState(["outils", "clients", "commercials"]),
    toolType() {
      const toolType = this.tool.ref.charAt(0).toLowerCase()

      switch (toolType) {
        case 'z':
          return {
            code: 'z',
            text: 'Circulaire Carbure'
          }
        case 'x': 
          return {
            code: 'x',
            text: 'Fraise scie'
          }
        default:
          return {
            code: '0',
            text: 'Type inconnu'
          }
      }
    },
    jour() {
      if (this.tool.client.data) return this.tool.client.data.attributes.jour
      else return ''
    }
  },
  data() {
    return {
      tool: {},
      loaded: false,
    };
  },
  methods: {
    ...mapActions(["getTool", "updateTool"]),
    loadTool() {
      const tool = this.outils.find((obj) => obj.id === this.$route.params.id);
      if (tool) this.tool === tool;
      else {
        this.getTool({ custom: `/${this.$route.params.id}?populate[client][populate][0]=commercial` })
          .then((response) => {
            if (response.data.data.length < 1) {
              this.$router.push({
                name: "404",
              });
            } else {
              this.loaded = true;
              this.tool = response.data.data.attributes;
              this.tool.caracs = JSON.stringify(this.tool.caracs)
              this.tool.id = response.data.data.id;
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status == 404) {
              this.$router.push({
                name: "404",
              });
            }
          });
      }
    },
    update(data) {
      const payload = {
        data: data,
        id: this.tool.id,
      };

      this.updateTool(payload)
      .then(() => {
        this.$swal
          .fire({
            position: "top-end",
            icon: "success",
            title: "Modification enregistrée",
            showConfirmButton: false,
            timer: 1000,
          })
          this.loadTool()
        })
      .catch((error) => {
        console.log(error);
        this.$swal("Erreur", "Oups, une erreur est survenue.", "error");
      });
    },
  },
  mounted() {
    this.loadTool();
  },
};
</script>