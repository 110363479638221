<template>
    <td>
      <p class="px-3 text-sm font-weight-bold mb-0">
       {{ sale.attributes.nom }}
      </p>
    </td>
    <td>
      <Popup :popup="popup" :sale="sale" />
      <material-button
        v-if="role === 'administrateur'"
        color="danger"
        size="sm"
        class="ms-2"
        @click="commercialDelete"
        >Supprimer</material-button
      >
    </td>
  </template>
  
  <script>
  import MaterialButton from "@/components/MaterialButton.vue";
  import Popup from "@/components/Popup.vue";
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    components: { 
      MaterialButton,
      Popup
    },
    name: "CommercialLine",
    props: {
        sale: {
            type: Object,
            required: true
        }
    },
    data() {
      return {
        popup: {
          title: "Modifier commercial",
          popuptype: 'updateCommercial',
          btn: {
            color: "success",
            size: "sm",
            class: "ms-2",
          }
        }
      }
    },
    computed: {
      ...mapGetters(['role']),
    },
    methods: {
      ...mapActions(['deleteCommercial']),
      commercialDelete() {
        this.$swal
          .fire({
            icon: "danger",
            title: `Supprimer ${this.sale.attributes.nom} définitivement?`,
            showDenyButton: true,
            denyButtonText: "Annuler",
            confirmButtonText: "Confirmer",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.deleteCommercial(this.sale.id)
              .then(response => {
                this.$swal.fire({
                  position: 'center-center',
                  icon: 'success',
                  title: `Commercial  ${response.data.data.attributes.nom} supprimé avec succès`,
                  showConfirmButton: false,
                  timer: 1000
                })
              })
              .catch((error) => {
                console.log(error);
                this.$swal(
                  "Erreur",
                  "Impossible de supprimer l'utilisateur",
                  "error"
                );
              })
            }
          });
      },
    },
  };
  </script>
  
  <style scoped>
    .btn-success {
      width: 100px;
    }
  </style>