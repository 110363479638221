<template>
  <div class="card mb-4 mb-lg-0">
    <div class="p-3 pb-2 mx-4 text-center card-header d-flex justify-content-center">
      <div
        :class="
          typeof icon === 'object' ? icon.background : 'bg-gradient-success'
        "
        class="icon icon-shape icon-lg shadow text-center border-radius-lg"
      >
        <i class="material-icons opacity-10" aria-hidden="true">{{
          typeof icon === "string" ? icon : icon.component
        }}</i>
      </div>
    </div>
    <div class="p-3 pt-0 text-center card-body">
      <h6 class="mb-0 text-center">{{ title }} 
        <popup-customer v-if="authorized" @updateCustomer="updateClient"></popup-customer>
      </h6>
      <div class="text pt-1 pb-2">{{ nomClient }}</div>
    </div>
  </div>
</template>

<script>
import PopupCustomer from '@/components/outils/PopupCustomer.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    PopupCustomer
  },
  name: "ClientInfoCard",
  props: {
    icon: {
      type: [String, Object],
      required: true,
      component: {
        type: String,
      },
      background: {
        type: String,
      },
      default: () => ({
        background: "bg-white",
      }),
    },
    title: {
      type: String,
      required: true,
    },
    client: {
      type: Object,
    },
  },
  data() {
    return {
      selected: {
        id: '',
      },
    }
  },
  emits: ['update'],
  computed: {
    ...mapState(["clients"]),
    ...mapGetters(["role"]),
    authorized() {
      return this.role === 'administrateur' || this.role === 'magasin'
    },
    nomClient() {
      if (this.client) {
        if(this.client.attributes) return this.client.attributes.nom
        else return 'Client non défini'
      }
      else return 'Client non défini'
    },
  },
  methods: {
    ...mapActions(["getCustomers"]),
    updateClient(data) {
      this.$emit('update', data)
    }
  }
};
</script>
