<template>
  <div class="text-center">
    <div
      class="px-2 d-flex justify-content-center justify-content-middle m-auto"
    >
      <div id="search-holder">
        <material-input
          id="search"
          label="Code outil"
          class="mb-5"
          size="lg"
          :modelValue="code"
          @update:modelValue="(newValue) => (code = newValue)"
          :autofocus="true"
          :maxLength="'8'"
        />
      </div>
    </div>
    <div v-if="loading == 'search'">
      <p>Recherche en cours</p>
    </div>
    <div v-else-if="loading == 'creation'">
      <p>Création en cours</p>
    </div>
    <div v-else>
      <div v-if="results == null" class="text-center">
        <p>Scannez ou saisissez un code outil pour lancer la recherche.</p>
      </div>
      <div
        v-else-if="results == 0"
        class="no-results d-flex justify-content-center align-items-center"
      >
        <p class="mb-0">Ce code ne correspond à aucun outil.</p>

        <material-button v-if="authorized" @click="createOutil" class="mx-3"
          >Créer l'outil</material-button
        >
      </div>
      <div v-else>
        <p>Il se trame quelque chose de très bizarre... Prévenez immédiatement un administrateur.</p>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Outils",
  data() {
    return {
      code: "",
      codeLength: 8,
      results: null,

      loading: ''
    };
  },
  components: {
    MaterialInput,
    MaterialButton,
  },
  watch: {
    code: function (val) {
      if (val.length == this.codeLength) {
        this.search();
      }
    },
  },
  computed: {
    ...mapGetters(["role"]),
    authorized() {
      return this.role === 'administrateur' || this.role === 'magasin'
    }
  },
  methods: {
    ...mapActions(["getTools", "createTool"]),
    search() {
      this.loading = 'search';

      let customFilter = `?filters[ref][$eq]=${this.code}&filters[disponible][$eq]=false`
      if(this.authorized) customFilter = `?filters[ref][$eq]=${this.code}`

      this.getTools({ custom: customFilter })
        .then((response) => {
          if (response.data.data.length == 1) {
            this.$router.push({
              name: "Fiche outil",
              params: { id: response.data.data[0].id },
            });
          } else this.results = response.data.data.length
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = '';

    },
    createOutil() {
      this.loading = 'creation';

      const data = {
        ref: this.code,
      };

      if (this.code == "" || this.code == null) {
        this.$swal(
          "Champs invalide",
          "Veuillez renseigner un code outil valide.",
          "error"
        );
        this.loading = '';
        return;
      }

      this.createTool({ data: data })
        .then((response) => {
          this.$router.push({
            name: "Fiche outil",
            params: { action: 'edit', id: response.data.data.id },
          });
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = '';
    },
  },
};
</script>
  
<style>

</style>