<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="qr-code-scanner"
          navText="Scanner QR Code"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">qr_code_scanner</i>
          </template>
        </sidenav-collapse>
      </li>
      <!-- <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="dashboard"
          navText="Tableau de bord"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">dashboard</i>
          </template>
        </sidenav-collapse>
      </li> -->
      <!-- <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="outils"
          navText="Outils"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">handyman</i>
          </template>
        </sidenav-collapse>
      </li> -->
      <li class="nav-item" v-if="role === 'administrateur'" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="remarques"
          navText="Remarques"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">report</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="role === 'administrateur' || role === 'magasin'" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="clients"
          navText="Clients"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">people</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="role === 'administrateur' || role === 'magasin'" >
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="commerciaux"
          navText="Commerciaux"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">card_travel</i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="role === 'administrateur' ">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="users"
          navText="Utilisateurs"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">manage_accounts</i>
          </template>
        </sidenav-collapse>
      </li>
      <!-- <li class="nav-item">
        <sidenav-collapse
          url="#"
          :aria-controls="''"
          v-bind:collapse="false"
          collapseRef="billing"
          navText="Billing"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">receipt_long</i>
          </template>
        </sidenav-collapse>
      </li> -->
    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import { mapGetters } from "vuex";

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active"
    };
  },
  components: {
    SidenavCollapse
  },
  computed: {
    ...mapGetters(["role"])
  }
};
</script>
