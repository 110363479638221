<template>
  <i class="material-icons ms-1 fs-6 cursor-pointer" @click="modalToggle">
    edit
  </i>
  <div
    class="modal fade" 
    :class="{ show: active, 'd-block': active }"
    id="users" 
    data-bs-backdrop="static" 
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="users"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Recherche client</h1>
          <button
            type="button"
            class="btn-close"
            @click="modalToggle"
            aria-label="Close"
          >
            <i class="material-icons-round fs-5">close</i>
          </button>
        </div>
        <div class="modal-body">
          <customer-search :tool="true" @selectCustomer="selectedCustomer"></customer-search>
        </div>
      </div>
    </div>
  </div>
  <div v-if="active" class="modal-backdrop fade show"></div>
</template>

<script>
import CustomerSearch from '@/components/CustomerSearch.vue';

export default {
  components: {
    CustomerSearch
  },
  data() {
    return {
      active: false
    }
  },
  name: "PopupCustomer",
  emits: ["update-customer"],
  methods: {
    modalToggle() {
      const body = document.querySelector("body")
      this.active = !this.active
      this.active ? body.classList.add("modal-open") : body.classList.remove("modal-open")
    },
    selectedCustomer(payload) {
      this.$emit('update-customer', payload)
      this.modalToggle()
    }
  }
}

</script>

<style>
.modal {
  z-index: 10000;
}
.btn-close i.material-icons-round {
color: black !important;
}
</style>