<template>
  <div class="modal-body">
    <div class="row">
      <div class="col-6 my-2">
        <material-input
          id="crm_code"
          type="text"
          label="Code CRM"
          name="crm_code"
          :modelValue="client.crm_code"
          @update:modelValue="(newValue) => (client.crm_code = newValue)"
        />
      </div>
      <div class="col-6 my-2">
        <material-input
          id="nom"
          type="text"
          label="Nom"
          name="nom"
          :modelValue="client.nom"
          @update:modelValue="(newValue) => (client.nom = newValue)"
        />
      </div>
      <div class="col-6 my-2">
        <select
          id="selectCommercial"
          v-model="client.commercial"
          :class="{ 'is-valid': client.commercial !== '' }"
          class="form-select w-100 px-3"
          data-style="btn btn-primary btn-round"
        >
          <option disabled value="">Sélectionner un commercial</option>
          <option v-for="c in commercials" :value="c.id" :key="c.id">
            {{ c.attributes.nom }}
          </option>
        </select>
      </div>
      <div class="col-6 my-2">
        <select
            id="select-jour"
            v-model="client.jour"
            :class="{ 'is-valid': client.jour != '' && client.jour != null }"
            class="form-select w-100 px-3"
            data-style="btn btn-primary btn-round"
          >
            <option disabled value="">Jour de tournée</option>
            <option value="lundi">Lundi</option>
            <option value="mardi">Mardi</option>
            <option value="mercredi">Mercredi</option>
            <option value="jeudi">Jeudi</option>
            <option value="vendredi">Vendredi</option>
            <option value="samedi">Samedi</option>
          </select>
      </div>
    </div>
  </div>
  <div v-if="!loading" class="modal-footer">
    <button type="button" class="btn btn-secondary" @click="modalToggle">
      Annuler
    </button>
    <button type="button" class="btn btn-primary" @click="clientUpdate">
      Enregistrer les modifications
    </button>
  </div>
  <div v-else class="modal-footer text-center">
    Modification du client en cours
  </div>
</template>
      
  <script>
import MaterialInput from "@/components/MaterialInput.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    MaterialInput,
  },
  props: {
    current: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      client: {
        crm_code: "",
        nom: "",
        commercial: "",
        jour: ""
      },

      loading: false,
    };
  },
  name: "ClientUpdate",
  computed: {
    ...mapState(["commercials"]),
  },
  emits: ["modaltoggle"],
  mounted() {
    this.client.crm_code = this.current.attributes.crm_code;
    this.client.nom = this.current.attributes.nom;
    this.client.jour = this.current.attributes.jour;
    this.client.commercial = this.current.attributes.commercial.data.id;
  },
  methods: {
    ...mapActions(["getCustomers","updateCustomer"]),
    modalToggle() {
      this.$emit("modaltoggle");
    },
    clientUpdate() {
      const toUpdt = {};
      let modif = false;
      this.loading = true;

      if (this.client.crm_code == "" || this.client.crm_code == null) {
        this.$swal(
          "Champs invalide",
          "Veuillez renseigner un code CRM.",
          "error"
        );
        this.loading = false;
        return;
      }

      if (this.client.nom == "" || this.client.nom == null) {
        this.$swal("Champs invalide", "Veuillez renseigner un nom.", "error");
        this.loading = false;
        return;
      }

      if (this.client.jour == "" || this.client.jour == null) {
        this.$swal("Champs invalide", "Veuillez sélectionner un jour.", "error");
        this.loading = false;
        return;
      }

      if (this.client.commercial == "" || this.client.commercial == null) {
        this.$swal
          .fire({
            title: "Attention",
            text: "Aucun commercial sélectionné. Continuer?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui, continuer",
            cancelButtonText: "Annuler",
          })
          .then((result) => {
            if (result.isCanceled) {
              this.loading = false;
              return;
            }
          });
      }

      if (this.client.crm_code != this.current.attributes.crm_code) {
        toUpdt.crm_code = this.client.crm_code;
        modif = true;
      }

      if (this.client.nom != this.current.attributes.nom) {
        toUpdt.nom = this.client.nom;
        modif = true;
      }

      if (this.client.jour != this.current.attributes.jour) {
        toUpdt.jour = this.client.jour;
        modif = true;
      }

      if (
        this.client.commercial != this.current.attributes.commercial.data.id
      ) {
        toUpdt.commercial = this.client.commercial;
        modif = true;
      }

      if (!modif) {
        this.loading = false;
        this.modalToggle();
        return;
      }

      const payload = {
        data: {
          data: toUpdt,
        },
        id: this.current.id,
      };

      this.updateCustomer(payload)
        .then(() => {
          this.modalToggle();
          this.$swal.fire({
            position: "center-center",
            icon: "success",
            title: `Client mis à jour`,
            showConfirmButton: false,
            timer: 1000,
          });
          this.getCustomers({
            populate: "?populate[0]=commercial",
          }).catch((error) => {
            console.log(error);
            this.$swal(
              "Erreur",
              "Impossible de récupérer la liste des clients",
              "error"
            );
            this.loading = false;
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("Erreur", "Oups, une erreur est survenue.", "error");
          this.loading = false;
        });
    },
  },
};
</script>
    