<template>
  <div class="card">
    <div class="p-3 pb-2 mx-4 text-center card-header d-flex justify-content-center">
      <div
        :class="
          typeof icon === 'object' ? icon.background : 'bg-gradient-info'
        "
        class="icon icon-shape icon-lg shadow text-center border-radius-lg"
      >
        <i class="material-icons opacity-10" aria-hidden="true">{{
          typeof icon === "string" ? icon : icon.component
        }}</i>
      </div>
    </div>
    <div class="p-3 pt-0 text-center card-body">
      <h6 class="mb-0 text-center">{{ title }}</h6>
      <div class="text pt-1 pb-2">{{ nomCommercial }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "ClientInfoCard",
  props: {
    icon: {
      type: [String, Object],
      required: true,
      component: {
        type: String,
      },
      background: {
        type: String,
      },
      default: () => ({
        background: "bg-white",
      }),
    },
    title: {
      type: String,
      required: true,
    },
    client: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["role"]),
    authorized() {
      return this.role === 'administrateur' || this.role === 'magasin'
    },
    nomCommercial() {
      if(this.client) return this.client.attributes.commercial.data.attributes.nom
      else return 'Commercial non défini'
    },
  },
};
</script>
