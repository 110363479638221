<template>
  <div class="card mt-lg-4 mb-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="my-2">Caractéristiques</h6>
        </div>
        <div class="col-6 text-end">
          <material-button
            v-if="authorized && modifCaracs"
            color="success"
            @click="saveCaracs"
            >Enregistrer</material-button
          >
        </div>
      </div>
    </div>
    <div class="card-body p-3 mb-0" :style="{ 'min-height': height }">
      <div class="row">
        <div class="col-12 my-1" v-for="(c, index) of updatedCaracs" :key="index">
          <div v-if="authorized">
            <material-input
              v-if=" c.type === 'text' "
              :id="c.slug"
              type="text"
              :label="c.name"
              :key="c.id"
              :modelValue="c.value"
              @update:modelValue="(newValue) => (c.value = newValue)"
            />
            <div class="d-flex align-items-center justify-content-between my-2" v-else-if=" c.type === 'switch' ">
              <span class="text text-sm">
                {{ c.name }}
              </span>
              <material-switch
                :id="c.slug"
                :name="c.slug"
                :checked="c.value"
                :modelValue="c.value"
                @update:modelValue="(newValue) => (c.value = newValue)"
              />
            </div>
            <div class="d-flex align-items-center justify-content-between " v-else-if=" c.type === 'select' ">
              <select
                :id="c.name"
                v-model="c.value"
                :class="{ 'is-valid': c.value != '' }"
                class="form-select w-100 px-3 m-0 py-2"
                data-style="btn btn-primary btn-round"
              >
                <option disabled value="">Géométrie</option>
                <option v-for="(o, index) in c.options" :key="index" :value="o.value">{{ o.text }}</option>
              </select>
            </div>
          </div>
          <div v-else class="text">{{ c.name }}: 
            <span v-if=" c.value === true "><strong>Oui</strong></span>
            <span v-else-if=" c.value === false "><strong>Non</strong></span>
            <span v-else><strong>{{ c.value }}</strong></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import { mapGetters } from "vuex";

export default {
  name: "CaracteristiquesCard",
  components: {
    MaterialButton,
    MaterialInput,
    MaterialSwitch,
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    caracs: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      updatedCaracs: [],
      currentCaracs: '',

      models: {
        // Circulaire Carbure
        z: [
          {
            name: 'Diamètre',
            slug: 'diametre',
            value: '',
            type: 'text' 
          },
          {
            name: 'Épaisseur pastille et corps de lame',
            slug: 'epaisseur',
            value: '',
            type: 'text' 
          },
          {
            name: 'Alésage',
            slug: 'alesage',
            value: '',
            type: 'text'
          },
          {
            name: 'Nombre de Dents',
            slug: 'dents',
            value: '',
            type: 'text'
          },
          {
            name: 'Information sur les trous de centrage',
            slug: 'centrage',
            value: '',
            type: 'text'
          },
          {
            name: 'Présence de clavette',
            slug: 'clavette',
            value: false,
            type: 'switch'
          },
          {
            name: 'Géométrie',
            slug: 'geometrie',
            value: '',
            type: 'select',
            options: [
              {
                text: 'Heller',
                value: 'heller'
              },
              {
                text: 'Alternée',
                value: 'alternee'
              },
              {
                text: 'Libre',
                value: 'libre'
              }
            ]
          }
        ],
        // Fraise Scie
        x: [
        {
            name: 'Diamètre',
            slug: 'diametre',
            value: '',
            type: 'text' 
          },
          {
            name: 'Épaisseur du corps de lame',
            slug: 'epaisseur',
            value: '',
            type: 'text'
          },
          {
            name: 'Alésage',
            slug: 'alesage',
            value: '',
            type: 'text'
          },
          {
            name: 'Nombre de Dents',
            slug: 'dents',
            value: '',
            type: 'text'
          },
          {
            name: 'Pas théorique',
            slug: 'pas',
            value: '',
            type: 'text'
          },
          {
            name: 'Information sur les trous de centrage',
            slug: 'centrage',
            value: '',
            type: 'text'
          },
          {
            name: 'Géométrie',
            slug: 'geometrie',
            value: '',
            type: 'select',
            options: [
              {
                text: 'Heller',
                value: 'heller'
              },
              {
                text: 'Alternée',
                value: 'alternee'
              },
              {
                text: 'Libre',
                value: 'libre'
              },
              {
                text: 'Brise Copeaux',
                value: 'brisecopeaux'
              }
            ]
          }
        ]
      }
    }
  },
  mounted() {
    this.setCaracs()
  },
  computed: {
    ...mapGetters(["role"]),
    authorized() {
      return this.role === 'administrateur' || this.role === 'operateur'
    },
    modifCaracs() {
      return this.currentCaracs !== JSON.stringify(this.updatedCaracs)
    },
    height() {
      if(this.authorized) return '442px'
      else return '432px'
    },
  },
  emits: ['update'],
  methods: {
    setCaracs() {
      if ( this.caracs != 'null' ) this.updatedCaracs = JSON.parse(this.caracs)
      else {
        switch(this.type) {
          case "x":
            this.updatedCaracs = this.models.x
            break
          case "z":
            this.updatedCaracs = this.models.z
            break
        }
      }
      this.currentCaracs = JSON.stringify(this.updatedCaracs)
    },
    saveCaracs() {
      const data = {
        data: {
          caracs: JSON.stringify(this.updatedCaracs)
        }
      }
      this.$emit('update', data)
    }
  }
};
</script>
