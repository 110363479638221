<template>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 my-2">
          <material-input
            :id="'nom-'+sale.id"
            type="text"
            label="Nom"
            name="nom"
            :modelValue="sale.attributes.nom"
            @update:modelValue="(newValue) => (sale.attributes.nom = newValue)"
          />
        </div>
      </div>
    </div>
    <div v-if="!loading" class="modal-footer">
      <button type="button" class="btn btn-secondary" @click="modalToggle">
        Annuler
      </button>
      <button type="button" class="btn btn-primary" @click="commercialUpdate">
        Enregistrer les modifications
      </button>
    </div>
    <div v-else class="modal-footer text-center">
      Modification du commercial en cours
    </div>
  </template>
      
  <script>
  import MaterialInput from "@/components/MaterialInput.vue";
  import { mapActions } from "vuex";
  
  export default {
    components: {
      MaterialInput,
    },
    props: {
      current: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        sale: {
          id: null,
          attributes: {
            nom: ''
          }
        },

        loading: false,
      };
    },
    name: "CommercialUpdate",
    emits: ["modaltoggle"],
    mounted() {
      this.sale.id = this.current.id
      this.sale.attributes.nom = this.current.attributes.nom;
    },
    methods: {
      ...mapActions(["updateCommercial"]),
      modalToggle() {
        this.$emit("modaltoggle");
      },
      commercialUpdate() {
        const toUpdt = {};
        let modif = false;
        this.loading = true;

        if (this.sale.attributes.nom == "" || this.sale.attributes.nom == null) {
            this.$swal("Champs invalide", "Veuillez renseigner un nom.", "error");
            this.loading = false;
            return;
        }
  
        if (this.sale.attributes.nom != this.current.attributes.nom) {
          toUpdt.nom = this.sale.attributes.nom;
          modif = true
        }
  
        if (!modif) {
          this.loading = false;
          this.modalToggle();
          return
        }

        const payload = {
            data: {
                data: toUpdt
            }, 
            id: this.current.id
        }
        
        this.updateCommercial(payload)
          .then( () => {
            this.modalToggle();
            this.$swal.fire({
              position: "center-center",
              icon: "success",
              title: `Commercial mis à jour`,
              showConfirmButton: false,
              timer: 1000,
            });
          })
          .catch((error) => {
            console.log(error);
            this.$swal("Erreur", "Oups, une erreur est survenue.", "error");
          });
        this.loading = false;
      },
    },
  };
  </script>
    